import React, { useState, memo } from "react"

import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../../transition/transition'

import ImageHandler from '../../imageHandler/imageHandler'
import shuffle from '../../../helpers/shuffle'

import style from './project.module.scss'

const Project = memo(({ post, filter }) => {
  const [ state ] = useState({
    // images: shuffle(post.postfields.images.map((image, i) => { image.key = i; return image })),
    imagesTop: 
      shuffle(post.postfields.images.map((image, i) => { 
        image.key = i; return image }
      ))
      .filter((item, i) => { if(i % 2 === 0){ return item }else{ return false }}),
    imagesBottom: 
      shuffle(post.postfields.images.map((image, i) => { 
        image.key = i; return image }
      ))
      .filter((item, i) => { if(i % 2 !== 0){ return item }else{ return false }}),
  })
  // let imagesTop    = state.images.filter((item, i) => { if(i % 2 === 0){ return item }else{ return false }})
  // let imagesBottom = state.images.filter((item, i) => { if(i % 2 !== 0){ return item }else{ return false }})

  // const category = post.categories ? post.categories.nodes[0].slug : false
  const category = filter

  return(
    <div className={style.project}>
      <div className={style.images} >
        <ImageHandler images={state.imagesTop} slug={post.slug} row={0} link={true} category={category}/>
        <ImageHandler images={state.imagesBottom} slug={post.slug}  row={1} link={true} category={category}/>
      </div>
      <div className={style.title}>
        <TransitionLink 
        to={`/projekte/${post.slug}/`}
        exit={exitTransition}
        entry={entryTransition}
        state={{ 
          imageId: false, 
          previousPost: false,
          // category: filter ? post.categories[0].slug || false : false
          category: category
        }}
        >
          <div className={style.info}>
            <div>
              <h2>{post.title}</h2>
            </div>
            <div>
              <h3>
                {post.postfields.titleExtended}<br />
                {post.postfields.type}<br />
                {/* {post.categories.nodes.map((item, i) => <span key={i}>--{item.slug}--</span>)} */}
                {post.postfields.year}<br />
              </h3>
            </div>
          </div>
        </TransitionLink>
      </div>
    </div>  
  )
})


export default Project