import React from 'react'
import Project from './index__project'
import style from './index.module.scss'
import InfiniteScroll from 'react-infinite-scroller';

const initialPost = 3
const newPosts = 3
// let count = 0

class Projects extends React.PureComponent{
    constructor(props){
        super(props)

        this.hasMore = props.posts.length <= newPosts ? false : true
        this.initialPosts = props.posts.length <= initialPost ? props.posts.length : initialPost
        this.state = {
            loaded: initialPost,
            posts: props.posts.slice(0, this.initialPosts),
            allPosts: props.posts,
            hasMore: this.hasMore
        }

        this.scrollParent = React.createRef();
        this.upDateElements = this.upDateElements.bind(this)
    }
   
    upDateElements(){
        let loaded = 0;
        let hasMore = true;

        if(this.state.allPosts.length - this.state.loaded >= newPosts){
            loaded = this.state.loaded + newPosts
        }else{
            loaded = this.state.length
            hasMore = false
        }

        this.setState({
            loaded: loaded,
            posts: this.state.allPosts.slice(0, loaded),
            hasMore: hasMore
        })
    }

    render(){
        
        return(
            <div ref={this.scrollParent} id="projects" className={`page ${style.projects}`} style={{
                height:"100%",
                overflow:"auto"
            }}>
                {/* {this.props.posts.map((post,i) => {
                    return <Project post={post} key={i} filter={this.props.filter}/>
                })} */}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.upDateElements}
                    hasMore={this.state.hasMore}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                    useWindow={false}
                    getScrollParent={() => this.scrollParent.current}
                >
                    {this.state.posts.map((post,i) => {
                        return <Project post={post} key={i} filter={this.props.filter}/>
                    })}
                </InfiniteScroll>
            </div>  
        )
    }
}




export default Projects